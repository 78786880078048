// @ts-nocheck
import React from 'react';
import { Link, injectIntl } from 'gatsby-plugin-intl';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import 'animate.css/animate.min.css';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Title from '../components/Title';
import heroImage from '../images/contact-us-background.jpg';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled(Row)`
  justify-content: center;
`;

const Heading = styled.h2`
  color: #074166;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  font-weight: bold;
  margin: 3rem 0;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100%;
  border: 1px solid #d6d6d6;
  padding: 3.5rem 0rem;
`;

const CardTitle = styled.h3`
  color: #074166;
  font-weight: bold;
  letter-spacing: 3px;
  margin: 1rem 0rem 2rem 0rem;
`;

const CardButton = styled(Link)`
  background: #074166;
  font-size: 1.2rem;
  border: 0px;
  padding: 0.6rem 1em;
  color: white;
  letter-spacing: 0.5px;
  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const CardContainer = styled(Col)`
  flex: 1 1 auto;
  margin: 1rem 0rem;
  padding: 0rem;
`;

const ContactUsPage = () => (
  <>
    <Layout>
      <SEO title="Contact Us" />
      <Wrapper className="container-fluid">
        <Title imageUrl={heroImage} position="top" text="Contact Us" centered />
        <Heading>Please select your type of enquiry</Heading>
        <StyledRow>
          <CardContainer lg={2}>
            <Card>
              <div>
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1575598309/Mystay%20English/Marketing%20Site/Vector.png"
                  alt="Students Icon"
                />
                <CardTitle>Participant Enquiries</CardTitle>
                <CardButton to="/contact-us/students">Contact Us</CardButton>
              </div>
            </Card>
          </CardContainer>
          <CardContainer lg={2}>
            <Card>
              <div>
                <img
                  src="https://res.cloudinary.com/mystay-international/image/upload/v1575598309/Mystay%20English/Marketing%20Site/Vector-1.png"
                  alt="Agent or Other Icon"
                />
                <CardTitle>Agents & Other</CardTitle>
                <CardButton to="/contact-us/other">Contact Us</CardButton>
              </div>
            </Card>
          </CardContainer>
        </StyledRow>
        <Footer />
      </Wrapper>
    </Layout>
  </>
);

export default injectIntl(ContactUsPage);
